@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.emptyListRegion {
  position: absolute;
  width: calc(100% - 10px);
  height: 100%; }

@media (min-width: 769px) {
  .emptyListRegion {
    width: calc(100% - 30px); } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.clamp {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; }
  .clamp-1 {
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-2 {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-3 {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-5 {
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-9 {
    -webkit-line-clamp: 9;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }

.column-count--2 {
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--3 {
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--4 {
  -moz-column-count: 4;
       column-count: 4;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--5 {
  -moz-column-count: 5;
       column-count: 5;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

/**************/
/*** COLORS ***/
/**************/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.clamp {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; }
  .clamp-1 {
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-2 {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-3 {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-5 {
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-9 {
    -webkit-line-clamp: 9;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }

.column-count--2 {
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--3 {
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--4 {
  -moz-column-count: 4;
       column-count: 4;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--5 {
  -moz-column-count: 5;
       column-count: 5;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

/**************/
/*** COLORS ***/
/**************/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-component img {
  max-width: 100%; }

.experience-component[class^='experience-commerce_layouts'], .experience-component[class*=' experience-commerce_layouts'] {
  margin-bottom: 1rem; }

.experience-component.experience-commerce_assets-button {
  display: inline-block; }
  .experience-component.experience-commerce_assets-button + .experience-commerce_assets-button {
    margin-left: 1rem; }

.experience-component .bg-accent {
  background-color: var(--skin-bg-accent); }

.image-component {
  margin: 0;
  width: 100%;
  position: relative; }

.common-image-component {
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: var(--focal-point-x) var(--focal-point-y);
     object-position: var(--focal-point-x) var(--focal-point-y); }
  .common-image-component.stretch {
    width: 100%; }

.common-image-filter {
  filter: brightness(40%); }

.image-heading-container {
  position: absolute;
  top: 50%;
  width: 100%; }
  @media (min-width: 544px) {
    .image-heading-container {
      padding-left: 7%; } }

.button-component .btn {
  margin: 1rem 0; }

.container .experience-component div.collapsible-xl button.title {
  color: var(--skin-primary-color);
  text-transform: none;
  font-size: 1rem;
  line-height: 1.3125rem;
  padding: 0.875rem 0; }
  .container .experience-component div.collapsible-xl button.title::after {
    content: "\f107";
    font-size: 1.5rem;
    line-height: 1;
    margin: 0; }

.container .experience-component div.collapsible-xl.active button.title::after {
  content: "\f106"; }

.experience-commerce_assets-accordionTopicCard {
  max-width: 40.3125rem; }

@media (max-width: 767.98px) {
  *[data-id="the-living-proof-content"] .region.col-sm-4 {
    padding: 0; } }

@media (min-width: 768px) {
  *[data-id="the-living-proof-content"] .region.col-sm-4 .experience-component,
  *[data-id="the-living-proof-content"] .region.col-sm-4 .further-living-proof-slider {
    height: 100%; } }

@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.experience-commerce_layouts-carousel .section-heading {
  margin: 1.375rem 0; }

.carousel {
  padding: 0; }
  .carousel-wrapper {
    margin-bottom: 2.5rem; }
    @media (min-width: 768px) {
      .carousel-wrapper > .container {
        padding: 0; } }
  .carousel-title {
    margin-bottom: 2.5rem; }
  .carousel.bg-accent {
    padding: 1rem 0; }

.pd-slider .product-tile-pd {
  margin: 0; }

.product-slider .product,
.pd-product-slider .product {
  padding: 0; }

.product-slider .slick-slide,
.pd-product-slider .slick-slide {
  width: 15.375rem;
  max-width: 15.375rem;
  height: auto;
  margin-right: 1.1875rem; }
  @media (min-width: 768px) {
    .product-slider .slick-slide,
    .pd-product-slider .slick-slide {
      width: 19.1875rem;
      height: auto;
      max-width: 19.1875rem;
      margin-right: 1.5rem; } }

.product-tile-pd .product-tile-body .color-swatches .swatches-ul {
  margin: 0; }

.pd-text-slider .pd-slide {
  display: none; }

.pd-text-slider.slick-initialized .pd-slide {
  display: block; }

.pd-text-slider .slick-prev {
  left: 0; }
  @media (min-width: 768px) {
    .pd-text-slider .slick-prev {
      left: 0.625rem; } }

.pd-text-slider .slick-next {
  right: 0; }
  @media (min-width: 768px) {
    .pd-text-slider .slick-next {
      right: 0.625rem; } }

@media (min-width: 768px) {
  .pd-text-slider.slick-dotted.slick-slider {
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .pd-text-slider .slick-dots {
    bottom: 0; } }

.pd-text-slider .slick-dots li {
  margin: 0 0.4375rem; }
  .pd-text-slider .slick-dots li button:before {
    height: 0.8125rem;
    width: 0.8125rem;
    color: #999;
    background-color: #999;
    border: 1px solid #999; }
  .pd-text-slider .slick-dots li.slick-active button:before {
    color: #0070d2;
    background-color: #0070d2;
    border: 1px solid #0070d2; }

.in-editor .carousel.edit-mode .pd-slider {
  display: flex;
  flex-wrap: wrap; }
  .in-editor .carousel.edit-mode .pd-slider .pd-slide {
    display: block; }

.in-editor .carousel.edit-mode .pd-product-slider .pd-slide {
  display: block;
  max-width: 18.75rem;
  float: left; }

.in-editor .carousel.edit-mode .pd-product-slider .product-tile {
  display: block; }

.in-editor .carousel.edit-mode .pd-product-slider .tile-image {
  display: block; }

.in-editor .carousel.edit-mode .carousel-wrapper {
  border: 0.625rem solid #ffdf5e;
  position: relative; }
  .in-editor .carousel.edit-mode .carousel-wrapper:before {
    content: "Carousel Edit Mode";
    padding: 0.3125rem 0.625rem 0.625rem;
    position: absolute;
    top: -0.4375rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
    background-color: #ffdf5e; }

.ig-gallery {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center; }
  .ig-gallery[data-type="flex"], .ig-gallery[data-type="Flex"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row; }
  .ig-gallery[data-type="masonry"], .ig-gallery[data-type="Masonry"] {
    display: block;
    width: 100%;
    -moz-column-gap: 0.625rem;
         column-gap: 0.625rem;
    -moz-column-count: 1;
         column-count: 1; }
    @media (min-width: 768px) {
      .ig-gallery[data-type="masonry"], .ig-gallery[data-type="Masonry"] {
        -moz-column-count: 3;
             column-count: 3; } }
    @media (min-width: 1200px) {
      .ig-gallery[data-type="masonry"], .ig-gallery[data-type="Masonry"] {
        -moz-column-count: 4;
             column-count: 4; } }
  .ig-gallery[data-type="carousel"], .ig-gallery[data-type="Carousel"] {
    opacity: 0;
    transition: opacity 0.3s ease-in; }
    .ig-gallery[data-type="carousel"].slick-initialized, .ig-gallery[data-type="Carousel"].slick-initialized {
      opacity: 1; }
    @media (max-width: 543.98px) {
      .ig-gallery[data-type="carousel"] .slick-next, .ig-gallery[data-type="Carousel"] .slick-next {
        right: 1rem; }
      .ig-gallery[data-type="carousel"] .slick-prev, .ig-gallery[data-type="Carousel"] .slick-prev {
        left: 1rem; } }
  .ig-gallery-title, .ig-gallery-description {
    display: block;
    text-align: center;
    margin: 0;
    padding: 0; }
  .ig-gallery-description {
    padding-bottom: 1rem; }
  .ig-gallery-container--hasText {
    padding: 1.875rem 0; }

.ig-tile {
  position: relative;
  display: inline-block;
  margin: 0.625rem;
  padding: 0;
  max-width: 100%;
  overflow: hidden; }
  .ig-tile,
  [data-type="flex"] .ig-tile,
  [data-type="Flex"] .ig-tile {
    max-width: none;
    flex: 1 0 calc(100% - 0.625rem); }
    @media (min-width: 544px) {
      .ig-tile,
      [data-type="flex"] .ig-tile,
      [data-type="Flex"] .ig-tile {
        flex: 1 0 calc(48% - 0.625rem); } }
    @media (min-width: 768px) {
      .ig-tile,
      [data-type="flex"] .ig-tile,
      [data-type="Flex"] .ig-tile {
        flex: 1 0 calc(24% - 0.625rem); } }
  .ig-gallery--native .ig-tile,
  [data-type="masonry"] .ig-tile,
  [data-type="Masonry"] .ig-tile {
    margin: 0.625rem 0.3125rem;
    padding: 0; }
    .ig-gallery--native .ig-tile .ig-tile-link,
    [data-type="masonry"] .ig-tile .ig-tile-link,
    [data-type="Masonry"] .ig-tile .ig-tile-link {
      padding-bottom: 0; }
    .ig-gallery--native .ig-tile .ig-tile-image,
    [data-type="masonry"] .ig-tile .ig-tile-image,
    [data-type="Masonry"] .ig-tile .ig-tile-image {
      position: static;
      transform: none; }
  .ig-gallery--carousel .ig-tile {
    margin: 0;
    padding: 0 0.625rem; }
    .ig-gallery--carousel .ig-tile .ig-tile-icon {
      right: 1.25rem; }
  .ig-tile-link {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%; }
  .ig-tile-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .ig-tile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    z-index: 1;
    padding: 0.1875rem;
    border-radius: 50%;
    box-shadow: var(--util-box-shadow);
    background-color: var(--skin-background-color-1); }
  .ig-tile-caption {
    color: #000;
    display: none; }
    [data-captions="true"] .ig-tile-caption {
      display: block; }
  [data-captions="true"] .ig-tile.hasCaption .ig-tile-link {
    padding-bottom: 0; }
  .ig-tile.hasCaption .ig-tile-link:hover {
    text-decoration: none; }
  [data-captions="true"] .ig-tile.hasCaption .ig-tile-image {
    position: relative;
    top: auto;
    left: auto;
    transform: none; }
  .ig-tile.hasCaption .ig-tile-caption {
    line-height: 1.25;
    margin: 0.3125rem 0 0;
    padding: 0 0.3125rem; }

.dynamic-cat-banner .page-banner {
  position: relative; }
  .dynamic-cat-banner .page-banner:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    @media (min-width: 1200px) {
      .dynamic-cat-banner .page-banner:before {
        max-width: 90rem;
        left: 15px; } }
  .dynamic-cat-banner .page-banner .page-heading-wrapper {
    display: flex;
    align-items: center; }
  .dynamic-cat-banner .page-banner .page-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 1200px) {
      .dynamic-cat-banner .page-banner .page-heading {
        margin: 0; } }
  .dynamic-cat-banner .page-banner .banner-image {
    padding: 0; }
    .dynamic-cat-banner .page-banner .banner-image img {
      width: 100%; }

.dynamic-cat-banner.text-only .page-banner .page-heading-wrapper {
  display: flex;
  align-items: flex-start; }

@media (min-width: 1200px) {
  .dynamic-cat-banner.text-only .page-banner .page-heading {
    padding-top: 3.125rem; } }

@media (min-width: 768px) {
  .dynamic-cat-banner.region .page-banner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 12%;
    height: 100%;
    z-index: -1; } }

@media (min-width: 768px) {
  .dynamic-cat-banner.region .page-banner .banner-image {
    margin-bottom: 4.125rem; } }

.experience-dynamic-productList {
  margin-top: 2rem; }
